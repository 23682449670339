<template>
  <div v-if="isReady" class="template-page">
    <vuestic-widget>
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Message Templates</span>
        </div>
      </template>
      <b-tabs v-model="activeTabIndex" card>
        <b-tab title="Text" @click="onTabClick">
          <List v-if="activeTabIndex === 0" provider="sms" />
        </b-tab>
        <b-tab title="EMail" @click="onTabClick">
          <List v-if="activeTabIndex === 1" provider="email" />
        </b-tab>
      </b-tabs>
    </vuestic-widget>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import List from "./Components/List";

export default {
  components: {
    List,
  },
  data() {
    return {
      isReady: false,
      activeTabIndex: 0,
    };
  },

  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },

  watch: {
  },

  mounted() {
    let provider = this.$route.query.type
    if (provider === 'email') {
      this.$nextTick(() => {
        this.activeTabIndex = 1
        this.isReady = true
      })
    } else {
      this.activeTabIndex = 0
      this.isReady = true
    }
  },


  methods: {
    onTabClick() {
      this.$nextTick(() => {
        let provider = this.activeTabIndex === 0 ? 'sms' : 'email'
        window.history.pushState({}, '', '/business/templates?type=' + provider)
      })
    },
  },
};
</script>
  
<style lang="scss">
.template-page {
  .widget-body {
    padding: 0;
  }

  .nav .nav-item {
    min-width: 70px;
    text-align: center;
  }
}
</style>
  